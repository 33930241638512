import github from "../assets/techs/github.png";
import pytorch from "../assets/techs/pytorch.png";
import python from "../assets/techs/python.png";
import postgresql from "../assets/techs/postgresql.png";
import overleaf from "../assets/techs/overleaf.png"
import docker from "../assets/techs/docker.png"
import android_studio from "../assets/techs/android_studio.png"
import reactjs from "../assets/techs/react.png"
import tailwind from "../assets/techs/tailwind.png"

// eslint-disable-next-line import/no-anonymous-default-export
export const techs_list = [
    {
      id: 1,
      src: python,
      title: "Python",
      style: "shadow-yellow-500",
    },
    {
      id: 2,
      src: pytorch,
      title: "Pytorch",
      style: "shadow-orange-500",
    },
    {
      id: 3,
      src: postgresql,
      title: "PostgreSQL",
      style: "shadow-cyan-500",
    },

    {
      id: 3,
      src: overleaf,
      title: "Overleaf",
      style: "shadow-green-400",
    },

    {
      id: 4,
      src: docker,
      title: "Docker",
      style: "shadow-blue-500",
    },

    {
      id: 5,
      src: android_studio,
      title: "Android Studio",
      style: "shadow-emerald-500",
    },
    
    {
      id: 6,
      src: reactjs,
      title: "ReactJS",
      style: "shadow-sky-500",
    },

    {
      id: 7,
      src: tailwind,
      title: "TailwindCSS",
      style: "shadow-teal-400",
    },

    {
      id: 8,
      src: github,
      title: "GitHub",
      style: "shadow-gray-400",
    },
  ];


export default techs_list
