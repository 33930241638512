export const publications_list = {
    2020: [
        {
            id: 1,
            link: 'http://ceur-ws.org/Vol-2882/paper41.pdf',
            name: 'HCMUS at Pixel Privacy 2020: Quality Camouflage with Back Propagation and Image Enhancement',
            description: "We employed multiple improved adversarial attack algorithms based on Fast Gradient Sign Method to degrade the quality of images while maintaining their quality scores assessed by the BiQA model",
            authors: 'Minh-Khoi Pham, Hai-Tuan Ho-Nguyen, Trong-Thang Pham, Hung Vinh Tran, Hai-Dang Nguyen, Minh-Triet Tran',
            site: 'CEUR Workshop Proceedings',
        },
    ],


    2021: [
        {
            id: 2,
            link: 'http://ceur-ws.org/Vol-3181/paper72.pdf',
            name: 'HCMUS at MediaEval 2021: Facial Data De-identification with Adversarial Generation and Perturbation Methods',
            description: "We employed adversarial attack algorithms on driver' faces while storing their identities in hidden representation",
            authors: 'Minh-Khoi Pham, Thang-Long Nguyen-Ho, Trong-Thang Pham, Hai-Tuan Ho-Nguyen, Hai-Dang Nguyen, Minh-Triet Tran',
            site: 'CEUR Workshop Proceedings',
        },
        {
            id: 6,
            link: 'https://doi.org/10.1016/j.cag.2021.07.010',
            name: 'SHREC 2021: Retrieval of cultural heritage objects',
            description: "Our team propose various methods to retrieve objects based on shape, with me introduce a 3D mesh-based approach by using MeshNet",
            authors: 'Ivan Sipiran, ..., Dinh-Huan Nguyen, Thang-Long Nguyen-Ho, Bang-Dang Pham, Minh-Khoi Pham, Quoc-Huy Trinh, The-Anh Vu-Le',
            site: 'Computer & Graphics',
        },
    ],


    2022: [

        {
            id: 3,
            link: 'https://drive.google.com/file/d/1IImyUiGCpZzt6YAUiDALJQ5NWLRYQ84f/view?usp=sharing',
            authors: "Thao Thi Phuong Dao, Minh-Khoi Pham, Mai-Khiem Tran, Bich Anh Trane, Ngoc Boi Van, Chanh Cong Ha, Minh-Triet Tran",
            name: 'Vision-based Assistance for Vocal Fold Identification in Laryngoscopy with Knowledge Distillation',
            description: 'We introduced a new  dataset about the identification of vocal fold along with an efficient model trained by knowledge distillation technique',
            site: 'poster at WiCV Workshop @ CVPR 2022',
        },

        {
            id: 4,
            link: 'https://doi.org/10.1016/j.cag.2022.07.018',
            name: 'SHREC 2022: pothole and crack detection in the road pavement using images and RGB-D data',
            description: '',
            authors: 'Elia Moscoso Thompson, Andrea Ranieri, Silvia Biasotti, Miguel Chicchon, Ivan Sipiran, Minh-Khoi Pham, Thang-Long Nguyen-Ho, Hai-Dang Nguyen, Minh-Triet Tran',
            site: "Computers & Graphics",
        },

        {
            id: 5,
            link: 'https://openaccess.thecvf.com/content/CVPR2022W/AICity/papers/Nguyen-Ho_Text_Query_Based_Traffic_Video_Event_Retrieval_With_Global-Local_Fusion_CVPRW_2022_paper.pdf',
            name: 'Text Query based Traffic Video Event Retrieval with Global-Local Fusion Embedding',
            description: 'We proposed an improved pipeline to perform video retrieval based on query texts about traffic events. Our approach achieved rank 5th overall, received runner-up award',
            authors: 'Thang-Long Nguyen-Ho, Minh-Khoi Pham, Tien-Phat Nguyen, Hai-Dang Nguyen, Minh N. Do, Tam V. Nguyen and Minh-Triet Tran',
            site: 'AI City Challenge Workshop @ CVPR 2022',
        },
    ],

    2023: [
        {
            id: 7,
            link: 'https://link.springer.com/chapter/10.1007/978-3-031-23911-3_15',
            name: 'Semi-supervised Organ Segmentation with Mask Propagation Refinement and Uncertainty Estimation for Data Generation',
            description: 'We present a novel two-staged method that employs various 2D-based techniques to deal with the 3D segmentation task. We propose using the recent state-of-the-art technique in video object segmentation, combining it with other semi-supervised training techniques to leverage the extensive unlabeled data. Moreover, we introduce a way to generate pseudo-labeled data that is both plausible and consistent for further retraining by using uncertainty estimation',
            authors: 'Minh-Khoi Pham, Thang-Long Nguyen-Ho, Thao Thi Phuong Dao, Tan-Cong Nguyen & Minh-Triet Tran',
            site: 'Lecture Notes in Computer Science',
        },
    ]

};

export default publications_list
